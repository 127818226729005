a {

  .arrow {
    display: inline-block;
    opacity: 0;
    transform: scale(0.5);
    transition: all 0.3s;
  }
  span {
    display: inline-block;
    padding-left: 5px;
    transform: translateX(-0.875em); // size of icon
    transition: transform 0.3s;
  }



}

.workshoptitle{
  text-decoration: none;
}

a:hover {
  fa-icon {
    opacity: 1;
    transform: scale(1);
  }
  span {
    transform: translateX(0);
  }
}

.btn-close{
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem #0d6efd40;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  background: #0000 var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: .375rem;
  box-sizing: initial;
  height: 1em;
  opacity: var(--bs-btn-close-opacity);
  padding: .25em;
  width: 1em
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}
