.slide-enter {
  transform: translateY(100px);
  opacity: 0;
}

.slide-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 400ms, transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slide-exit {
  transform: translateY(0);
  opacity: 1;
}

.slide-exit-active {
  transform: translateY(100px);
  opacity: 0;
  transition: opacity 400ms, transform 400ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
