.spinnersOverlay {
  background-color: rgba(51, 51, 51, 0.8);
  z-index: 99;
  opacity: 1;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
.spinnersLoading {
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
}
